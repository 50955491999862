// Generated automatically, please don't write here

window._env_ = {
  APICONFIG_TENANT: "https://login.microsoftonline.com/7788edaf-0346-4068-9d79-c868aed15b3d",
  APICONFIG_CLIENT_ID: "156820bb-8193-4cc8-86a8-b710fb6b6756",
  APICONFIG_BASEPATH: "/apiconfig/api/v1",
  APICONFIG_REDIRECT_URI: "https://config.dev.platform.pagopa.it/",
  APICONFIG_SCOPES: "api://pagopa-d-apiconfig-be/access-apiconfig-be",
  APICONFIG_HOST: "https://api.dev.platform.pagopa.it",
  APICONFIG_BASEPATH_ALT: "",
};
